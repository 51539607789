export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Bonusliste',
    route: 'bonus',
    icon: 'BatteryChargingIcon',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Einstellungen',
    icon: 'SettingsIcon',
    resource: 'Dashboard',
    action: 'read',
    children: [
      {
        title: 'Massenmailing',
        icon: 'SendIcon',
        route: 'partner-bulk-email-list',
        resource: 'Dashboard',
        action: 'read',
      },
      {
        title: 'FIN löschen',
        icon: 'DeleteIcon',
        route: 'delete-entries',
        resource: 'Dashboard',
        action: 'read',
      },
      {
        title: 'Benutzer löschen',
        icon: 'DeleteIcon',
        route: 'delete-entries-user',
        resource: 'Dashboard',
        action: 'read',
      },
      {
        title: 'CSV import',
        icon: 'ArrowUpCircleIcon',
        route: 'csv-import',
        resource: 'Dashboard',
        action: 'read',
      },
      /*
      {
        title: 'E-Mail-Anhänge',
        icon: 'PaperclipIcon',
        route: 'partner-mail-attachments',
        resource: 'Dashboard',
        action: 'read',
      },
      {
        title: 'SMTP',
        route: 'partner-smtp',
        resource: 'Dashboard',
        action: 'read',
      },
      */
    ],
  },
]
